<template>
  <div class="wscn-http404-container">
    <div class="wscn-http404">
      <div class="pic-404">
        <img class="pic-404__parent" src="@/assets/edp/404_images/404.png" alt="404" />
        <img class="pic-404__child left" src="@/assets/edp/404_images/404_cloud.png" alt="404" />
        <img class="pic-404__child mid" src="@/assets/edp/404_images/404_cloud.png" alt="404" />
        <img class="pic-404__child right" src="@/assets/edp/404_images/404_cloud.png" alt="404" />
      </div>
      <div class="bullshit">
        <div class="bullshit__oops">错误!</div>
        <div class="bullshit__headline">{{ message }}</div>
        <a href="/" class="bullshit__return-home">返回首页</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Page404',
    computed: {
      message() {
        return '抱歉，页面未找到！'
      }
    }
  }
</script>

<style lang="scss">
  .wscn-http404-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    .wscn-http404 {
      position: relative;
      width: 9.375rem;
      padding: 0 .3906rem;
      overflow: hidden;
      .pic-404 {
        position: relative;
        float: left;
        width: 4.6875rem;
        overflow: hidden;
        &__parent {
          width: 100%;
        }
        &__child {
          position: absolute;
          &.left {
            width: .625rem;
            top: .1328rem;
            left: 1.7188rem;
            opacity: 0;
            animation-name: cloudLeft;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            animation-delay: 1s;
            animation-iteration-count: infinite;
          }
          &.mid {
            width: .3594rem;
            top: .0781rem;
            left: 3.2813rem;
            opacity: 0;
            animation-name: cloudMid;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            animation-delay: 1.2s;
            animation-iteration-count: infinite;
          }
          &.right {
            width: .4844rem;
            top: .7813rem;
            left: 3.9063rem;
            opacity: 0;
            animation-name: cloudRight;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            animation-delay: 1s;
            animation-iteration-count: infinite;
          }
          @keyframes cloudLeft {
            0% {
              top: .1328rem;
              left: 1.7188rem;
              opacity: 0;
            }
            20% {
              top: .2578rem;
              left: 1.4688rem;
              opacity: 1;
            }
            80% {
              top: .6328rem;
              left: .7188rem;
              opacity: 1;
            }
            100% {
              top: .7578rem;
              left: .4688rem;
              opacity: 0;
            }
          }
          @keyframes cloudMid {
            0% {
              top: .0781rem;
              left: 3.2813rem;
              opacity: 0;
            }
            20% {
              top: .3125rem;
              left: 2.8125rem;
              opacity: 1;
            }
            70% {
              top: 1.0156rem;
              left: 1.4063rem;
              opacity: 1;
            }
            100% {
              top: 1.25rem;
              left: .9375rem;
              opacity: 0;
            }
          }
          @keyframes cloudRight {
            0% {
              top: .7813rem;
              left: 3.9063rem;
              opacity: 0;
            }
            20% {
              top: .9375rem;
              left: 3.5938rem;
              opacity: 1;
            }
            80% {
              top: 1.4063rem;
              left: 2.6563rem;
              opacity: 1;
            }
            100% {
              top: 1.5625rem;
              left: 2.3438rem;
              opacity: 0;
            }
          }
        }
      }
      .bullshit {
        position: relative;
        float: left;
        width: 2.3438rem;
        padding: .2344rem 0;
        overflow: hidden;
        &__oops {
          font-size: .25rem;
          font-weight: bold;
          line-height: .3125rem;
          color: #a6291a;
          opacity: 0;
          margin-bottom: .1563rem;
          animation-name: slideUp;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
        &__headline {
          font-size: .1563rem;
          line-height: .1875rem;
          color: #222;
          font-weight: bold;
          opacity: 0;
          margin-bottom: .0781rem;
          animation-name: slideUp;
          animation-duration: 0.5s;
          animation-delay: 0.1s;
          animation-fill-mode: forwards;
        }
        &__info {
          font-size: .1016rem;
          line-height: .1641rem;
          color: grey;
          opacity: 0;
          margin-bottom: .2344rem;
          animation-name: slideUp;
          animation-duration: 0.5s;
          animation-delay: 0.2s;
          animation-fill-mode: forwards;
        }
        &__return-home {
          display: block;
          float: left;
          width: .8594rem;
          height: .2813rem;
          background: #a6291a;
          border-radius: .7813rem;
          text-align: center;
          color: #ffffff;
          opacity: 0;
          font-size: .1094rem;
          line-height: .2813rem;
          cursor: pointer;
          animation-name: slideUp;
          animation-duration: 0.5s;
          animation-delay: 0.3s;
          animation-fill-mode: forwards;
        }
        @keyframes slideUp {
          0% {
            transform: translateY(.4688rem);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }
</style>
